<template>
  <div class="container">
    <h1 class="title">Video Upload</h1>
    <div class="columns">
      <div class="column is-one-fifth">
        <h2 class="subtitle">Upload Video</h2>
        <input type="file" @change="onFileChange" accept="video/*" />
        <button class="button is-primary" @click="uploadVideo">Upload</button>
        <progress class="progress" v-if="uploading" :value="uploadProgress" max="100">
          {{ uploadProgress }}%
        </progress>
      </div>
      <div class="column is-four-fifths">
        <h2 class="subtitle">Videos</h2>
        <div class="columns is-multiline">
          <div class="column is-one-third" v-for="video in videos" :key="video.name">
            <div class="card">
              <div class="card-content">
                <video controls :src="`/_media/${video.name}`" width="100%"></video>
                <p>Size: {{ (video.size / 1024 / 1024).toFixed(2) }} MB</p>
                <input type="text" v-model="video.newName" placeholder="Rename video" />
                <button class="button is-info" @click="compressVideo(video)">Compress</button>
                <button class="button is-danger" @click="deleteVideo(video.name)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      file: null,
      uploading: false,
      uploadProgress: 0,
      videos: [],
    };
  },
  mounted() {
    this.fetchVideos();
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploadVideo() {
      if (!this.file) return;

      this.uploading = true;
      const formData = new FormData();
      formData.append('video', this.file);

      try {
        const response = await axios.post('http://localhost:3300/upload', formData, {
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });
        this.uploading = false;
        this.uploadProgress = 0;
        console.log(response.data);
        this.fetchVideos();
      } catch (error) {
        console.error(error);
        this.uploading = false;
      }
    },
    async fetchVideos() {
      try {
        const response = await axios.get('http://localhost:3300/videos');
        this.videos = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async compressVideo(video) {
      try {
        await axios.post('http://localhost:3300/compress', { filename: video.name });
        this.fetchVideos(); // Refresh video list after compression
      } catch (error) {
        console.error(error);
      }
    },
    async deleteVideo(name) {
      try {
        await axios.delete(`http://localhost:3300/delete/${name}`);
        this.fetchVideos(); // Refresh video list after deletion
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}
</style>
